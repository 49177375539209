.section--fnf {
  background: $dark-gray;
  padding-top: $section-margin;
}

.fnf {
  background: $dark-gray;
  color: $white;
  padding-top: rem-calc(10);
  padding-bottom: 0;

  @include breakpoint(large) {
    padding-top: rem-calc(40);
  }
  .fnf__wrapper {
    text-align: center;
    padding-right: rem-calc(10);
    padding-left: rem-calc(10);
    padding-bottom: rem-calc(40);
  }
}

.fnf--main {
  margin-top: rem-calc(40);
  margin-bottom: rem-calc(20);

  .fnf__wrapper {
    padding-right: rem-calc(20);
    padding-bottom: rem-calc(40);
  }
}

.fnf__figure {
  margin-bottom: 1rem;
  color: $orange;
}

.fnf__subject {
  font-size: rem-calc(17);
  line-height: rem-calc(32);
}

.fnf__description {
  font-size: rem-calc(17);
  margin-bottom: 2 * $general-block-margin;
}

// reset for project overrides
h3.fnf__heading,
div.fnf__figure {
  @extend %font-bold;
  margin-top: 0;
  font-size: rem-calc(25);
}

;@import "sass-embedded-legacy-load-done:140";