@import "node_modules/foundation-sites/scss/util/util";
@import "node_modules/foundation-sites/scss/foundation";

$grid-margin-gutters: rem-calc(16);
@include foundation-xy-grid-classes;

@include foundation-text-alignment;
@include foundation-visibility-classes;

$responsive-embed-ratios: (
  default: 653 by 370,
  blogtn: 388 by 191,
);
@include foundation-responsive-embed;

.page--standard {
  @include foundation-forms;

  select {
    width: 100%;
  }
}

;@import "sass-embedded-legacy-load-done:2";