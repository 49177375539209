.side-content {
  font-size: $body-font-size-meta;
  line-height: rem-calc(25);

  // fix off-grid margins
  @include breakpoint(large) {
    &.grid-container.full {
      margin-left: 2 * $general-block-margin;
    }
  }

  .cell {
    margin-bottom: $general-block-margin;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include breakpoint(large) {
      align-items: unset;
    }
  }
  &__section {
    margin-top: $section-margin;
    margin-bottom: 0.75 * $general-block-margin;
  }
  .involved {
    h4 {
      font-size: inherit;
      margin-bottom: inherit;
      line-height: inherit;
    }
    p {
      font-size: inherit;
      line-height: inherit;
    }
  }

  .ce-rt {
    h3 {
      @extend %neat-font;
      margin-bottom: rem-calc(5);
    }
    a[href^="tel"] {
      position: relative;
      border-bottom: none;
      margin-left: $icon-size + 0.5rem;
      &::before {
        content: " ";
        position: absolute;
        left: -$icon-size - 0.5rem;
        display: inline-block;
        font-size: 0;
        width: $icon-size;
        height: $icon-size;
        background-size: cover;
        background-image: url("assets/icons/tel.svg");
        background-color: $gray-blue;
        border-radius: 50%;
      }
    }
    a[href^="mailto"] {
      position: relative;
      border-bottom: none;
      margin-left: $icon-size + 0.5rem;
      &::before {
        content: " ";
        position: absolute;
        left: -$icon-size - 0.5rem;
        display: inline-block;
        font-size: 0;
        width: $icon-size;
        height: $icon-size;
        background-size: cover;
        background-image: url("assets/icons/mail.svg");
        background-color: $gray-blue;
        border-radius: 50%;
      }
    }
  }

  h3 {
    @extend %neat-font;
    font-weight: 700;
  }

  .competencies {
    .card {
      width: 289px;
      height: 4.5rem;
      color: $darker-gray;
      margin-bottom: $general-block-margin;
    }
  }
}

@include breakpoint(large) {
  .side-content > div > *:first-child {
    margin-top: 0;
  }
}

;@import "sass-embedded-legacy-load-done:126";