.home-headline {
  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &__h1 {
    color: $dark-gray;
    margin-bottom: 0;
    @include resprop("font-size", (rem-calc(40), rem-calc(50), rem-calc(60)));
    @include resprop("padding", (rem-calc(20), rem-calc(40), rem-calc(80)));
    @include resprop(
      "padding-bottom",
      (rem-calc(10), rem-calc(10), rem-calc(10))
    );
  }
}

.home-phases {
  color: $dark-gray;
  background-color: $lighter-gray;
  &__wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  &__phase {
    text-align: center;
    min-width: 12.5rem;
    margin-bottom: $general-block-margin;
  }
  &__headline {
    margin: $section-margin 0;
    max-width: 500px;
  }
  &__caption {
    font-size: rem-calc(20);
  }
}

;@import "sass-embedded-legacy-load-done:124";