.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.left-grid-offset {
    @include breakpoint(medium) {
      iframe {
        left: 12%;
        width: 88%;
        height: 88%;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:165";