.lightbox-area {
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.92);
  z-index: z-index("lightbox");
}
.lightbox {
  display: none;
}
.lightbox.active {
  display: block;
}
.lightbox__inner {
  max-height: 100vh;
}
.lightbox-area.active {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lightbox__control {
  display: flex;
  justify-content: space-between;
  margin-bottom: $general-block-margin;
}
.lightbox-icon {
  width: rem-calc(48);
  height: rem-calc(48);
  margin-right: 0;
}
.lightbox__caption {
  text-align: center;
  color: $white;
}

;@import "sass-embedded-legacy-load-done:161";