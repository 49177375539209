.teaser-link {
  &__wrapper {
    color: $orange;
    float: right;
  }
  &__link {
    display: flex;
  }
  &__span {
    @extend %font-medium;
    font-size: rem-calc(15);
    margin-right: 1rem;
  }
}

;@import "sass-embedded-legacy-load-done:155";