$icons: "twitter", "linkedin", "xing", "search", "mail", "tel", "download",
  "arrow-orange", "arrow-orange-left", "arrow-orange-up", "close", "arrow-white",
  "arrow-white-left", "play-circle", "arrow-blue";

$icon-size: rem-calc(26);

@mixin icons {
  @each $icon in $icons {
    &.#{$icon} {
      background-image: url("assets/icons/#{$icon}.svg");
    }
  }
}

.icon {
  display: inline-block;
  font-size: 0;
  width: $icon-size;
  height: $icon-size;
  background-size: cover;
  margin-right: rem-calc(12);

  @include icons();

  @include transition(all, 0.25s);
  &:hover {
    opacity: 0.7;
  }
}

.jumpy--right .icon:not(.play-circle) {
  margin-right: 0;
}
.jumpy--left:hover .icon:not(.play-circle) {
  transform: translateX(-10px);
}
.jumpy--right:hover .icon:not(.play-circle) {
  transform: translateX(10px);
}

;@import "sass-embedded-legacy-load-done:119";