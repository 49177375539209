.submenu {
  ul {
    padding-left: 0;
    list-style-type: none;
  }
  a {
    @extend %font-medium;
    display: block;
    box-shadow: $box-shadow;
    border-radius: 6px;
    padding: 1rem 2.33rem 1rem 1.33rem;
    background-image: url("assets/icons/arrow-blue.svg");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    &:hover {
      background-color: $dark-gray;
      color: $white;
    }
  }
}

;@import "sass-embedded-legacy-load-done:148";