.contacts__box {
  display: flex;
  position: relative;
  align-items: center;
  padding: 10px;
  margin-bottom: $general-block-margin;

  .contacts__overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
  }

  .contacts__img {
    min-width: 82px;
    img {
      width: 82px;
      border-radius: 50%;
    }
  }

  .contacts__info-text {
    margin-bottom: initial;
    line-height: rem-calc(22);
    margin-bottom: 1em;
  }

  .contacts__meta {
    margin-top: auto;
    line-height: 1em;
    &-icon {
      background-color: $gray-blue;
      border-radius: 50%;
      width: rem-calc(28);
      height: rem-calc(28);
      margin-right: 0.33rem;
      z-index: 2;
      position: relative;
    }
  }

  &.horizontal {
    max-width: 380px;
    box-shadow: $box-shadow;
    border-radius: 6px;
    .contacts__info {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding-left: 10px;
    }
  }

  &.vertical {
    flex-direction: column;
    text-align: center;
    .contacts__info {
      min-width: 70%;
      font-size: rem-calc(15);
      @extend %font-bold;
    }
    .contacts__img img {
      width: 137px;
      margin-bottom: 1em;
    }
    .contacts__meta {
      padding-top: 1em;
      border-top: 1px solid $light-gray;
    }
  }
}

a.contacts__box:hover {
  opacity: 0.7;
}

.person-teaser {
  &__grid {
    @include xy-grid;
    @include xy-grid-layout(1, ".contacts__box");
    @include breakpoint(medium) {
      @include xy-grid-layout(2, ".contacts__box");
    }
    @include xy-gutters($grid-margin-gutters, "margin", (right, left), true);
  }
}

.side-content .person-teaser__grid {
  justify-content: center;
  @include breakpoint(medium) {
    @include xy-grid-layout(1, ".contacts__box");
  }
  @include breakpoint(large) {
    justify-content: normal;
  }
}

.full-column .person-teaser__grid {
  @include breakpoint(large) {
    @include xy-grid-layout(3, ".contacts__box");
  }
}

;@import "sass-embedded-legacy-load-done:136";