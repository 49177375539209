.competencies-grid {
  > .cell {
    border-bottom: 1px solid $light-gray;
    padding-block: 2rem;
  }
}

.competencies,
.projects {
  .title {
    margin-top: 2rem;
    @include breakpoint(large) {
      margin-top: unset;
    }
  }
}

.section--competencies .cell:has(.title) {
  margin-bottom: 0;
}

.page--competencies .competencies-grid {
  .foc-block {
    padding-block: 0;
    margin-block: 0;
  }
  h2 {
    font-size: 1.5rem;
    @include breakpoint(large) {
      font-size: 1.75rem;
    }
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    .foc-with-specs-link {
      border-bottom: 1px solid transparent;
      &:hover,
      &:focus-visible {
        border-bottom-color: $light-gray-blue;
      }
    }
  }
  .specialization-link__grid {
    margin-bottom: 1rem;
    &:has(li) {
      margin-bottom: 1.5rem;
    }
  }
}

.section--specialization {
  a.specialization-anchor {
    cursor: initial;
  }

  h2.spec__h2 {
    font-weight: 700;
    font-size: rem-calc(16);
    margin-top: 0;
  }
}

.specialization-link__grid {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: -0.25rem;
  padding: 0;
}

.specialization-link__box {
  margin: 0.25rem;
  border: 1px solid $gray-blue;
  border-radius: 6px;
  &:hover,
  &:focus {
    background: $gray-blue;
    color: $white;
  }
}

.specialization-link__grid {
  margin-bottom: 1rem;
}

.specialization-link__link {
  font-weight: bold;
  display: block;
  padding: 0.5rem 0.8rem 0.4rem 0.8rem;
}

.cell.card.foc-card {
  height: 5rem;
}

;@import "sass-embedded-legacy-load-done:133";