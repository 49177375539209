$black: #202020;
$white: #ffffff;

// main colors
$lightest-gray: #f4f6f6;
$lighter-gray: #e5e5e5;
$light-gray: #dadad9;
$neat-gray: #9b9a9b;
$medium-gray: #878786;
$dark-gray: #4a4949;
$darker-gray: #2b2b2b;
$dark-blue: #005470;
$gray-blue: #73a5be;
$light-gray-blue: #86c4e3;
$blue: #009ee3;

$pink: #e3358b;
$wine: #822565;
$orange: #ec652d;
$light-orange: #ec932d;
$yellow: #ffb248;
$green: #42ad4a;

$primary: $dark-blue;
$secondary: $wine;
$headings-color: $black;

$primary-gray: $light-gray;
$secondary-gray: $medium-gray;

$box-shadow: 0px 3px 6px #00000029;

;@import "sass-embedded-legacy-load-done:114";