// will output the property for increasing breakpoint sizes
// (breakpoints defined in $breakpoints for foundation)
// example usage:
// h1 {
//   @include resprop('font-size', (2.5rem, 3rem));
//   @include resprop('text-align', (start, center));
// }

$z-index-map: (
  "pdf-reader": 500,
  "lightbox": 400,
  "scroll-to-top": 350,
  "mobile-nav": 300,
  "disturber": 250,
  "sticky-nav": 200,
  "project-teaser-offset": 100,
);

@function z-index($key) {
  @return map-get($z-index-map, $key);
}

@mixin resprop($property, $values) {
  $i: 1;
  @each $value in $values {
    @include breakpoint(nth($breakpoints, $i)) {
      #{$property}: $value;
    }
    $i: $i + 1;
  }
}

@mixin transition($style, $time: 0.4s) {
  transition: $style $time ease-in-out;
}

@mixin line-clamp($num-of-lines, $line-height: 1) {
  max-height: 1em * $line-height * $num-of-lines;
  overflow: hidden;
  @supports (-webkit-line-clamp: $num-of-lines) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $num-of-lines;
  }
}

;@import "sass-embedded-legacy-load-done:115";