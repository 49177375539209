.quote {
  margin: $general-block-margin 0;
  .text-neat {
    @extend %font-bold;
    font-size: rem-calc(13);
    line-height: inherit;
  }
}

.quote--prominent {
  margin: $general-block-margin 0;
  background-color: $gray-blue;
  color: $white;
  .text-neat {
    @extend %font-bold;
    color: inherit;
  }
  .quote {
    &__grid {
      margin: $general-block-margin;
      @include breakpoint(medium) {
        margin: $section-margin 0;
      }
    }
    &__text {
      @extend %font-bold;
      font-size: rem-calc(25);
    }
    &__meta {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      img {
        width: rem-calc(84);
        height: rem-calc(84);
        border-radius: 50%;
        margin-right: 1rem;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:162";