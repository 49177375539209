// includes some default reset, see also https://github.com/jaydenseric/Fix/blob/master/fix.css
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;

  scroll-behavior: smooth;
}
*,
*:after,
*:before {
  box-sizing: inherit;
}
main {
  display: block;
  height: 100%;
  flex: 1 0 auto;
  overflow: hidden;
}
img {
  border: 0;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
::-moz-focus-inner {
  border: 0;
  padding: 0;
}

;@import "sass-embedded-legacy-load-done:0";