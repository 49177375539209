.ce-img {
  margin: $general-block-margin 0;
  &__img {
    display: inline-block;
    position: relative;
  }
  // no copyright in picture
  // &__cpr {
  //   position: absolute;
  //   right: 0;
  //   bottom: 0;
  //   color: $white;
  //   font-size: rem-calc(8);
  // }
  &__caption {
    font-size: $body-font-size-small;
    margin-top: 0.5em;
    text-align: center;
  }
  &__wrapper {
    display: inline-block;
    text-align: center;
  }
}

span.caption + span.copyright:before {
  content: " | ";
}

;@import "sass-embedded-legacy-load-done:130";