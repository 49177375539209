.double-header {
  &__img-wrapper {
    text-align: center;
  }
  &__img {
    border-radius: 3px;
  }
  &__img-wrapper.wide {
    display: none;
    @include breakpoint(medium) {
      display: block;
    }
  }
}

;@import "sass-embedded-legacy-load-done:131";