$top-offset: 8rem;

.home-projects {
  &__title {
    // color: $white;
    z-index: z-index("project-teaser-offset");
  }
  &__sub.section.background {
    padding-top: 4rem + $top-offset;
    padding-bottom: $section-margin;
    background-color: $darker-gray;
    margin-top: -$top-offset;
    color: $white;
  }
}
.project-teaser {
  &__title {
    max-width: 1034px;
    display: flex;

    &__img {
      overflow: hidden;
      border-radius: 3px;
    }

    &__img img {
      @include transition(transform);
      border-radius: 6px;
      margin-right: $general-block-margin;

      &:hover {
        transform: scale(1.1);
      }
    }

    .teaser-link {
      &__wrapper {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        @include breakpoint(large) {
          flex-direction: column;
          min-height: 50%;
          align-items: normal;
        }
      }
      &__icon {
        @include breakpoint(large) {
          width: rem-calc(48);
          height: rem-calc(48);
        }
      }
      &__span {
        @include breakpoint(large) {
          display: inline-block;
          white-space: nowrap;
          order: 50;
          margin-left: 10px;
          transform: rotate(90deg);
          transform-origin: 0;
        }
      }
    }
  }
  &__sub {
    &__h2 {
      font-size: rem-calc(25);
    }
    &__h3 {
      color: $white;
      font-size: rem-calc(20);
    }
  }
}

;@import "sass-embedded-legacy-load-done:154";