.ce-dl {
  margin-bottom: rem-calc(6);

  &__icon {
    width: rem-calc(25);
    height: rem-calc(25);
    margin-right: rem-calc(6);
  }

  &__link {
    display: flex;
    align-items: center;
    width: fit-content;
    padding-bottom: 1px;
    border-bottom: 2px solid $gray-blue;
    &:hover {
      color: $gray-blue;
    }
  }
}

.ce-dl + div:not(.ce-dl) {
  margin-top: $general-block-margin;
}

;@import "sass-embedded-legacy-load-done:143";