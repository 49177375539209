// Media Slider

.section.full-column .ce-slider {
  text-align: center;
  .media-slider__slide {
    margin-left: auto;
    margin-right: auto;
  }
}

.ce-slider {
  margin-top: $general-block-margin;
}

.media-slider__cell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.media-slider__slide {
  max-width: 590px;
  & .ce-img__wrapper {
    display: block;
  }
  & img.ce-img__img {
    max-height: 320px;
  }
  position: relative;
  margin-bottom: 2 * $general-margin;
}

.media-slider__slides {
  overflow: hidden;
  position: relative;
}

.media-slider__slider {
  display: flex;
}

.media-slider__thumbs {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -3rem;
  left: 50%;
  transform: translateX(-50%);

  &.media-slider__thumbs--facts {
    left: 0;
    position: relative;
    transform: none;
    bottom: auto;
    justify-content: flex-start;

    a[data-thumb] {
      &::before {
        background-color: $white;
      }
    }
  }

  @include breakpoint(medium) {
    bottom: -4rem;
  }

  a[data-thumb] {
    position: relative;
    height: 30px;
    width: 30px;
    margin: 0;
    padding: 0;
    background: none;
    border: 0 none;
    outline: none;
    cursor: pointer;

    &::before {
      background-color: $light-gray;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      height: 10px;
      width: 10px;
      transform: translate3d(-5px, -5px, 0px);
      transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      border-radius: 50%;
      opacity: 0.7;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transform: scale(0);
      transition:
        transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
        opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
        -webkit-transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      border: 3px solid $light-gray;
      opacity: 0;
      border-radius: 50%;
    }

    &:hover {
      opacity: 1;
    }

    &[data-current] {
      opacity: 1;
      position: relative;
      transition: all ease-in-out 200ms;

      &::after {
        transform: scale(0.8);
        opacity: 1;
        border-color: $gray-blue;
      }

      &::before {
        background: $gray-blue;
        opacity: 1;
      }
    }
  }
}

.media-slider__go-wrapper {
  position: absolute;
  top: 45%;
  width: 3rem;
  height: 3rem;

  display: none;

  @include breakpoint(medium) {
    display: block;
  }

  &.media-slider__go-wrapper--right {
    position: absolute;
    right: 1rem;
    z-index: 1;
  }
}

.media-slider__go {
  display: block;
  width: 3rem;
  height: 3rem;

  &[data-go="1"] {
    &:after {
      content: url("assets/icons/arrow-orange.svg");
      transform: translate(-40%, -38%);
    }
  }

  &:hover {
    &:before {
      border: 5px solid $light-gray;
      padding: 2rem;
    }
  }
}

;@import "sass-embedded-legacy-load-done:142";