.jobs {
  &__apply {
    margin-top: $section-margin;
    display: flex;
    align-items: center;
    .btn {
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
  &__sub_title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.125rem;
    letter-spacing: 0.020625rem;
    text-transform: uppercase;
    line-height: 1.5625rem;
  }

  &__footer {
    @include resprop("margin-top", (1rem, 1.5rem, 2rem));
  }
}

.page--jobs {
  .card {
    display: block;
    background-color: $gray-blue;
    color: $white;

    &-meta {
      padding-bottom: 8px;
      border-bottom: 1px solid $white;
      display: block;
      color: inherit;
      @extend %neat-font;
    }

    &-label {
      padding-top: 10px;
      display: block;
      padding-bottom: 10px;
    }
  }
  .jobs__sub_title {
    text-transform: none;
  }
  .intro {
    font-weight: 700;
    margin-bottom: 2rem;
  }

  .duty,
  .requirement {
    ul {
      list-style: "  •  ";

      @include breakpoint(medium) {
        columns: auto 2;
        column-gap: 1.5rem;

        li {
          page-break-inside: avoid; /* For Firefox. */
          -webkit-column-break-inside: avoid; /* For Chrome & friends. */
          break-inside: avoid;
        }
      }
    }
  }

  .site-search-pill__wrapper .grid-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    margin-top: 0.5rem;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;

    @include breakpoint(medium) {
      gap: 1.5rem;
      align-items: center;
      flex-direction: row;
    }
  }

  .site-search-pill__pill {
    float: none;
    margin-top: unset;
  }

  .jobbtns__btn {
    display: inline-block;
    background: $orange;
    color: $white;
    border-radius: 10px;
    text-align: center;
    padding: 0.75rem 3rem 0.65rem;
    border: $orange 2px solid;

    @include breakpoint(small only) {
      flex-basis: 100%;

      &.desktop {
        display: none;
      }
    }

    @include breakpoint(medium) {
      &.mobile {
        display: none;
      }
    }
  }

  .jobbtns__btn--agent {
    color: $orange;
    border: 2px solid;
    background: #fff;
  }
}

.jobs-parent-url {
  box-shadow: $box-shadow;
  border-radius: 6px;
  background-color: white;
  padding: 1rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5rem;
}

.jobs-parent-url__icon {
  height: rem-calc(20);
  width: rem-calc(20);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
  pointer-events: none;
}

.jobs-teaser {
  .card {
    display: block;
    background-color: $gray-blue;
    color: $white;

    &-meta {
      padding-bottom: 8px;
      border-bottom: 1px solid $white;
      display: block;
      color: inherit;
      @extend %neat-font;
    }

    &-label {
      padding-top: 10px;
      display: block;
      padding-bottom: 10px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:147";