.works-here {
  background-color: $darker-gray;

  padding-bottom: rem-calc(64);

  &__h2 {
    color: $white;
  }
}

section.works-here:last-child {
  margin-bottom: 0;
}

;@import "sass-embedded-legacy-load-done:168";