.section--future {
  background: $gray-blue;
  padding-top: $section-margin;
}

.future--end {
  background: $gray-blue;
  color: $black;
  padding-top: rem-calc(10);
  padding-bottom: 0;

  @include breakpoint(large) {
    padding-top: rem-calc(40);
  }
  .future__wrapper {
    padding-bottom: rem-calc(40);
  }
  .future__heading {
    margin-bottom: rem-calc(48);
  }
}

.future--main {
  padding-top: rem-calc(40);
  padding-bottom: rem-calc(40);
  .future__wrapper {
    background: $gray-blue;
    color: $black;
  }
  .future__heading {
    margin-bottom: rem-calc(26);
  }
}

.future__issue-type {
  @extend %neat-font;
  color: inherit;
  margin-bottom: 0;
}

.future__inner-top {
  margin-bottom: rem-calc(14);
  font-size: rem-calc(15);
}

.future__inner-content {
  font-size: rem-calc(14);
  margin-bottom: $general-block-margin;
}

// reset for project overrides
h3.future__heading {
  @extend %font-bold;
  margin-top: 0;
  font-size: rem-calc(25);
}

;@import "sass-embedded-legacy-load-done:146";