.btn,
.button {
  display: inline-block;
  color: $white;
  background: $gray-blue;
  @extend %meta-font;
  padding: 0.66rem 1.33rem;
  text-align: center;
  @include transition(all, 0.15s);
  border: 0;
  border-radius: 10px;

  margin: 0;
  text-align: inherit;
  text-transform: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  cursor: pointer;
  overflow: visible;

  &:hover {
    background-color: $light-gray-blue;
  }

  &[type="reset"] {
    padding-right: 2.33rem;
    background-image: url("assets/icons/reset.svg");
    background-repeat: no-repeat;
    background-position: right 1rem center;
  }
}

;@import "sass-embedded-legacy-load-done:118";