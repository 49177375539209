.ce-rt {
  margin-right: auto;
  a[href] {
    border-bottom: 2px solid $gray-blue;
    &:hover {
      color: $gray-blue;
    }
  }

  a:not([href]) {
    scroll-margin-top: rem-calc(150);
  }

  h1,
  h2,
  h3,
  h4 {
    scroll-margin-top: rem-calc(150);
  }

  h2 {
    margin-top: rem-calc(78);
  }

  h3 {
    margin-top: rem-calc(48);
  }
}

.ce-rt > p,
.text-lead {
  // readability
  max-width: 50rem;

  &.intro * {
    font-weight: 600;
  }
}

.ce-rt:first-of-type > h2 {
  margin-top: $general-block-margin;
}
.ce-rt:first-of-type > h2.spec__h2 {
  margin-top: 0;
}

;@import "sass-embedded-legacy-load-done:152";