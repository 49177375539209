.edit-tools {
  padding: 1rem;
  text-align: center;
  z-index: 100;
  pointer-events: none;

  a {
    pointer-events: auto;
  }
}

;@import "sass-embedded-legacy-load-done:157";