.card {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: $dark-gray;
  font-size: rem-calc(15);
  border-radius: 0.3rem;
  margin-bottom: $general-block-margin;
  &.box {
    box-shadow: $box-shadow;
    padding: rem-calc(10) rem-calc(12);
  }
}
a.card.box:hover {
  color: $white;
  background-color: $dark-gray;
}

.competencies,
.filter-item {
  .card-label,
  .filter-item__label {
    @extend %meta-font;
    font-size: rem-calc(15);
    cursor: pointer;
  }
}

.cr__foc .filter-item__label {
  height: 4.5rem;
}

.card-info__bottom {
  margin-top: auto;
  width: 100%;
}

// competence cards have fixed height and respect grid margins
.competencies .grid-x > .cell,
.cr__foc .grid-x > .cell .card {
  margin-bottom: 0;
}

;@import "sass-embedded-legacy-load-done:134";