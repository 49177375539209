.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  left: 20px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.scroll-to-top-button {
  background-color: white;
  border-radius: 30px;
  font-size: 0.875rem;
  color: $orange;
  /* text-transform: upercase; // Typo from the time it was introduced, deactivate. */
  display: block;
  padding: 10px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: $box-shadow;
  z-index: z-index("scroll-to-top");

  &.hidden {
    display: none;
  }
}

/* Move the margin to the button text so that it's not there when showing the button without the text */
.scroll-to-top-button .icon {
  margin-right: 0;
}

.scroll-to-top-button__text {
  margin-left: 0.75rem;
}

.scroll-to-top-button__text.md-up {
  display: none;

  @include breakpoint(medium) {
    display: block;
  }
}

;@import "sass-embedded-legacy-load-done:166";