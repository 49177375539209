.breadcrumb {
  &__wrapper {
    display: flex;
    align-items: center;
  }
  &__link {
    display: inline-block;
  }
  &__icon {
    height: rem-calc(20);
    width: rem-calc(20);
  }
  &__span {
    @extend %font-medium;
    font-size: $body-font-size-meta;
    color: $orange;
  }
}

;@import "sass-embedded-legacy-load-done:150";