.benefits {
  &__sub-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.9375rem;
    letter-spacing: 0.020625rem;
    pointer-events: none;
    line-height: 1.5625rem;
    text-align: center;
    margin-top: rem-calc(24);
    margin-bottom: 0;
  }

  &__benefit-container {
    perspective: 1000px;
    margin-bottom: 1rem;

    &:hover .benefits__benefit-inner,
    &:active .benefits__benefit-inner {
      transform: rotateY(180deg);
    }
  }

  &__benefit-inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    box-shadow: $box-shadow;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    background: #ffffff;
    border-radius: 6px;
    perspective: 1000px;
    cursor: pointer;
    height: 100%;
    min-height: 240px;
  }

  &__benefit-front,
  &__benefit-back {
    grid-area: 1 / 1 / 1 / 1;
    padding: 1rem;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    border-radius: 6px;
    font-weight: 700;
  }

  &__benefit-front {
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2.5rem 1rem 1.5rem 1rem;
  }

  &__benefit-back {
    background-color: $gray-blue;
    color: white;
    transform: rotateY(180deg) translateZ(1px);
    ul {
      list-style: "  •  ";
    }

    h2 {
      margin-top: 0;
      margin-bottom: 1rem;
      text-align: left;
    }
  }

  &__icon {
    display: block;
    height: 100px;
    width: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px;
    pointer-events: none;

    &--conditions {
      background-image: url("assets/icons/Anstellungsbedingungen_Icon.png");
    }
    &--provisions {
      background-image: url("assets/icons/Vorsorge_Icon.png");
    }
    &--career {
      background-image: url("assets/icons/Karriere_Icon.png");
    }
    &--further-education {
      background-image: url("assets/icons/Weiterbildung_Icon.png");
    }
    &--fringe-benefits {
      background-image: url("assets/icons/Benefits_Icon.png");
    }
    &--work-environment {
      background-image: url("assets/icons/Umfeld_Icon.png");
    }
  }
}

;@import "sass-embedded-legacy-load-done:172";