#inscriptionMailPro {
  display: block;

  #Container {
    display: flex;
    flex-wrap: wrap;
  }

  label {
    margin-bottom: 0.2rem;
    font-weight: 600;
    font-size: 0.875rem;
  }

  .form-group {
    flex-basis: 50%;
    padding-right: rem-calc(20);
    display: flex;
    flex-direction: column;
    margin-bottom: rem-calc(20);

    @include breakpoint(small only) {
      flex-basis: 100%;
      padding-right: 0;
    }

    input {
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 2.4375rem;
      margin: 0 0 1rem;
      padding: 0.5rem;
      border: 1px solid #cacaca;
      border-radius: 0;
      background-color: #fefefe;
      box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
      font-family: inherit;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5;
      color: #0a0a0a;
      transition:
        box-shadow 0.5s,
        border-color 0.25s ease-in-out;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    &--submit {
      flex-basis: 100%;
      padding-right: rem-calc(18);
      @include breakpoint(small only) {
        padding-right: 0;
      }
    }
  }

  .submit-wrapper {
    width: 100%;

    .btn {
      width: 100%;
      text-align: center;
      border-radius: 0;
      background-color: $gray-blue;
      color: $white;
      padding: 1rem 2rem;
      cursor: pointer;
    }
  }
}

;@import "sass-embedded-legacy-load-done:170";