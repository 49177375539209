$filter-margin: rem-calc(5);

.section--competencies,
.section--jobs,
.section--projects,
.section--events {
  .competencies,
  .jobs,
  .projects,
  .events {
    &:not(:first-child) {
      @include resprop(
        "margin-top",
        (rem-calc(10), rem-calc(15), rem-calc(30))
      );
    }
    @include resprop(
      "margin-bottom",
      (rem-calc(10), rem-calc(15), rem-calc(30))
    );

    .title {
      margin-bottom: 0;
      margin-right: 1rem;
      display: inline-block;
    }
  }

  .pill-btn {
    .filter-item__label {
      cursor: pointer;
      font-size: rem-calc(13);
      color: $dark-gray;
      display: table;
      margin: $filter-margin;
      border-radius: 2em;
      box-shadow: $box-shadow;
      text-align: center;

      & icon {
        display: table-cell;
        vertical-align: middle;
        width: rem-calc(39);
        height: rem-calc(39);
        &:not(.phase-icon) {
          transform: translate(0px, rem-calc(2));
        }
      }

      & span {
        display: table-cell;
        vertical-align: middle;
        padding: rem-calc(9);
      }

      & icon + span {
        padding-left: 0px;
      }
    }
  }

  @include breakpoint(large) {
    panel.phases {
      justify-content: flex-end;
    }
  }

  .phases {
    .filter-item__label {
      @include breakpoint(medium) {
        display: flex;
        flex-direction: column;
        align-items: center;
        > icon {
          display: flex;
          align-items: center;
          width: 6rem;
          height: 6rem;
          justify-content: center;
          font-size: 2rem;
          + span {
            padding: rem-calc(9);
            position: relative;
            top: -2px;
          }
        }
      }
    }
  }

  // invert checked labels
  .filter-item__check:checked + .filter-item__label,
  .filter-item__check:focus + .filter-item__label,
  .filter-item__check:not(:disabled) + .filter-item__label:hover {
    color: $orange;
  }

  input:disabled + label {
    opacity: 0.5;
  }

  // map
  .cr__regions {
    &__wrap {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: column;
      width: 100%;
      @include breakpoint(medium) {
        flex-direction: row;
      }
    }
    &__regions {
      display: inline-block;
      flex-grow: 1;
      text-align: left;

      label {
        padding: 0.3em 1.2em;
        display: block;
        cursor: pointer;

        &:hover {
          color: $gray-blue;
        }
      }
      input:checked + label {
        color: $gray-blue;
      }
    }
    &__map {
      display: inline-block;
      flex-grow: 2;
      svg {
        fill: $medium-gray;
        stroke: $white;
        height: auto;
        width: 100%;
        max-height: 500px;
        g:hover,
        g.hover,
        g.active {
          fill: $dark-gray;
        }
        g.lake {
          fill: $gray-blue;
        }
      }
    }
  }
}

.filter-item__check:focus + .cr__regions__regions__region {
  color: $gray-blue;
}

.filter__error {
  display: none;
  margin-bottom: 0;
  border-left: #e85851 4px solid;
  padding: 1rem;
  border-radius: 0 rem-calc(7) rem-calc(7) 0;

  box-shadow: $box-shadow;
  background: $white;

  [data-error="true"] & {
    display: block;
  }
}

//  counters
.hit-count {
  font-size: $body-font-size-small;
  color: $medium-gray;
}

span.hit-count::before {
  content: attr(data-hits) " ";
}

/* Load more button / Pagination */

.filter__load-more-wrapper {
  display: flex;
  justify-content: center;
}

@keyframes loading-bounce {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

.filter__load-more {
  position: relative;
  overflow: hidden;

  $length: 1rem;

  &::before {
    content: "";
    display: block;
    width: $length;
    height: $length;
    background-color: $white;

    position: absolute;
    top: 100%;
    left: calc(50% - #{$length / 2});
    border-radius: 50%;

    will-change: transform;
    animation: loading-bounce 0.8s ease-in-out infinite alternate;

    transition: top ease 0.21s;
  }

  [data-loading~="pagination"] & {
    pointer-events: none;

    &::before {
      top: calc(50% - #{$length / 2});
      transition-delay: 0.1s;
    }
  }
}

.filter__load-more-label {
  display: inline-block;
  transform: translateY(0);
  transition: transform ease 0.21s;

  [data-loading~="pagination"] & {
    transform: translateY(-2em);
  }
}

/* Filter result list loading indicator */

.filter__loading {
  position: relative;
  height: 0;
  overflow: hidden;

  transition: height 0.2s ease;
  transition-delay: 0.1s;

  will-change: height;

  [data-loading~="filter"] & {
    height: 10rem;
    transition-delay: 0;
  }
}

.filter__loading-circle {
  $length: 1.25rem;

  width: $length;
  height: $length;
  background-color: $gray-blue;

  position: absolute;
  top: calc(50% - #{$length / 2});
  border-radius: 50%;

  will-change: transform;
  animation: loading-bounce 0.8s ease-in-out infinite alternate;

  transition: ease 0.21s;
  transition-property: opacity, visibility;

  opacity: 0;
  visibility: hidden;

  &:nth-child(1) {
    left: calc(50% - #{$length * 2});
  }

  &:nth-child(2) {
    left: calc(50% - #{$length / 2});
    animation-delay: 0.15s;
  }

  &:nth-child(3) {
    left: calc(50% + #{$length});
    animation-delay: 0.3s;
  }

  [data-loading~="filter"] & {
    opacity: 1;
    visibility: visible;
    transition-delay: 0.1s;
  }
}

;@import "sass-embedded-legacy-load-done:137";