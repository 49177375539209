.f3cc {
  --f3cc-background: #{$dark-gray};
  --f3cc-foreground: #fff;
  --f3cc-button-background: #73a5be;
  --f3cc-accept-background: #ec652d;
  --f3cc-button-foreground: #fff;
  --f3cc-accept-foreground: #fff;
}

.f3cc-button {
  border-radius: 30px;
}

;@import "sass-embedded-legacy-load-done:164";