$toggle-breakpoint: large;
$meta-nav-height: rem-calc(32);

.nav-container {
  position: fixed;
  z-index: z-index("sticky-nav");
  width: 100%;
  background-color: $white;
  @include transition(box-shadow);

  /**
   * SCROLL EFFECTS
   */
  &.scrolled {
    box-shadow: $box-shadow;
  }

  /**
   * TOGGLE EFFECTS
   */
  &.toggled {
    background-color: $darker-gray;
    z-index: z-index("mobile-nav");
    color: $white;
    position: fixed;
    height: 100vh;
    overflow: scroll;

    .nav {
      flex-direction: column;
    }

    // NAV
    .nav .nav__list {
      margin-top: rem-calc(25);
      display: flex;
      text-align: center;
      flex-direction: column;
    }
    .nav .nav__list__item {
      font-size: rem-calc(25);
      margin: rem-calc(16) auto;
    }

    // META NAV
    .nav .nav__meta-wrapper {
      position: inherit;
      display: block;
      height: auto;
      background: inherit;
    }

    .nav .nav__meta-list {
      margin-top: $general-block-margin;
      display: flex;
      flex-direction: column;
      padding-left: 0;
      text-align: center;
    }

    .nav .nav__meta-list__item {
      display: block;
      margin-left: 0;
      margin-top: rem-calc(12);
      font-size: rem-calc(22);
    }

    // TOGGLE BUTTON
    .nav__trigger {
      display: block;
    }

    .nav__trigger .icon {
      background-image: url("assets/icons/close.svg");
    }

    .mob-toggle-hide {
      display: none;
    }
  }
}

.nav {
  @include resprop(
    "padding-top",
    (rem-calc(12), rem-calc(12), $meta-nav-height)
  );
  @include resprop("padding-bottom", (rem-calc(12), rem-calc(12), 0));
  @include transition(padding-top);
  display: flex;
  justify-content: space-between;
  // flex-wrap: wrap;

  // NAV
  .nav__list {
    display: none;
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    @include breakpoint($toggle-breakpoint) {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
    }
  }

  .nav__list__item {
    @extend %font-bold;
    margin: rem-calc(32) 0 rem-calc(26) rem-calc(48);
    font-size: rem-calc(14);
    display: block;
    > span {
      text-transform: uppercase;
    }
  }

  .nav__list__item.is-active span {
    @extend %font-black;
  }

  .nav__list__item.is-active span,
  .nav__list__item:hover span {
    color: $gray-blue;
  }

  // META NAV
  .nav__meta-wrapper {
    @include transition(height);
    @include breakpoint($toggle-breakpoint) {
      display: block;
    }
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: $black;
    color: $white;
    height: $meta-nav-height;
  }

  .nav__meta-list {
    width: 100%;
    display: block;
    margin-bottom: 0;
    text-align: right;
    @include breakpoint(large) {
      display: flex;
      height: 2rem;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .nav__meta-list__item {
    display: inline-block;
    font-size: $body-font-size-small;
    padding: rem-calc(4) 0;
    margin-left: rem-calc(12);
    a:hover {
      text-decoration: underline;
    }
  }

  // TOGGLE
  .nav__trigger {
    display: flex;
    text-align: right;
    @include breakpoint($toggle-breakpoint) {
      display: none;
    }
    &__link {
      display: inline-block;
    }
    &__icon {
      display: block;
      height: 42px;
      width: 42px;
      background-image: url("assets/icons/menu.svg");
      margin-right: 0;
    }
  }
}

// LOGO
.nav__logo {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  &-link {
    display: block;
  }
}

;@import "sass-embedded-legacy-load-done:132";