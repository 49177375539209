.filter-pills {
  position: relative;

  .filter-pills__pills-wrapper {
    margin: 1rem;
    flex-wrap: wrap;
    flex-direction: column;
    @include breakpoint(large) {
      margin: 0 0 1rem 0;
      flex-direction: row;
      justify-content: center;
      align-items: initial;
    }
  }

  pill {
    cursor: pointer;
    position: relative;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    border: 1px solid $light-gray;
    border-radius: 30px;
    height: rem-calc(58);
    text-align: left;
    display: flex;

    &:not(:first-of-type) {
      margin-top: 1rem;
      @include breakpoint(large) {
        margin-top: 0;
      }
    }
  }

  > .filter-pills__mobile-collapse {
    margin-top: 3rem;

    @include breakpoint(medium) {
      margin-top: unset;
    }

    border: 1px solid $dark-gray;
    border-radius: 3px;

    @include breakpoint(large) {
      border: none;
    }

    > .mobile-collapse {
      cursor: pointer;
      display: flex;
      align-items: center;
      border-bottom: none;
      @include breakpoint(large) {
        display: none;
      }
    }
    > .filter-pills__pills-wrapper {
      display: none;
    }
    @include breakpoint(large) {
      > .filter-pills__pills-wrapper {
        display: flex;
      }
    }
    &.expanded > .filter-pills__pills-wrapper {
      display: flex;
    }
    &.expanded > .mobile-collapse {
      border-bottom: 1px solid $dark-gray;
    }
  }

  @include breakpoint(large) {
    pill {
      flex: 1 0 auto;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-left: none;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    pill.first-of-type,
    pill.first {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      border-left: 1px solid $light-gray;
    }
    pill:last-of-type,
    pill.last {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }
    panel {
      order: 50;
    }
  }

  .search-pill {
    min-width: 3rem;
    flex: 0 1 auto;

    font-size: rem-calc(15);
  }

  .collapse-pill .pill-label,
  .mobile-collapse .pill-label {
    margin-left: 0;
  }

  panel.open {
    margin-top: 1rem;
  }

  panel.open.grid-container.full {
    margin-left: 3px;
    margin-right: 3px;
  }

  panel {
    display: flex;
    align-self: flex-end;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    visibility: hidden;
    height: 0px;
  }

  .icon-before::before {
    content: " ";
    background-repeat: no-repeat;
    background-position: center;
    width: 3rem;
    height: 3rem;
    display: inline-block;
    align-self: center;
    margin-left: 1rem;
  }

  .with-filter-icon::before {
    background-image: url("assets/icons/black-filter.svg");
  }

  .with-search-icon::before {
    background-image: url("assets/icons/black-search.svg");
  }

  .collapse-icon-after::after {
    content: " ";
    background-image: url("assets/icons/collapse-arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    margin-left: auto;
    margin-right: 1rem;
  }

  .expanded > .collapse-icon-after,
  .collapse-icon-after.open {
    &::after {
      background-image: url("assets/icons/collapse-arrow-up.svg");
    }
  }

  panel.open {
    visibility: visible;
    height: auto;
    &.cr__foc {
      display: block;
    }
  }

  pill.collapse-pill {
    display: none;
    cursor: pointer;
  }

  .search-pill-input {
    padding: 1rem;
    background-color: inherit;
  }

  @include breakpoint(large) {
    .competencies & .search-pill.expanded ~ pill:not(.collapse-pill, #ot-pill),
    .projects & .search-pill.expanded ~ pill:not(.collapse-pill) {
      display: none;
    }

    .search-pill.expanded + pill.collapse-pill {
      display: flex;
    }

    .search-pill:not(.expanded) .search-pill-input {
      width: 0;
      margin-right: 0;
    }
  }

  .pill-label {
    margin: 0 0 0 1rem;
    padding: 1rem;
    margin: 0 0 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .open .pill-label {
    @extend %font-bold;
  }

  pill,
  div.mobile-collapse {
    > .pill-cnt:not([data-cnt="0"]) {
      width: rem-calc(14);
      height: rem-calc(14);
      border-radius: rem-calc(7);
      background: $orange;
      color: $white;
      font-size: rem-calc(9);
      font-weight: bold;
      text-align: center;
      padding-top: rem-calc(1);
      transform: translateY(-(rem-calc(12)));
      &::after {
        content: attr(data-cnt);
      }
    }
  }

  .search-pill > .pill-cnt:not([data-cnt="0"]) {
    margin-right: 1rem;
    margin-left: auto;
  }

  .fp-btn {
    &__wrapper {
      :not(:last-child) {
        margin-right: 1rem;
      }
      position: absolute;
      top: -4rem;
      right: 0;
      display: block;
      text-align: right;
      margin-bottom: 1rem;
      > * {
        margin: 3px 0;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:139";