.section--breadcrumb + section {
  margin-top: $general-block-margin;
}
.section {
  margin-top: $section-margin;

  &:first-of-type {
    margin-top: 1rem;
  }
  @include breakpoint(medium) {
    &:first-of-type {
      margin-top: $section-margin;
    }
  }

  &--competence {
    padding-bottom: rem-calc(48);
  }
  &--specialization {
    padding-top: rem-calc(48);
    padding-bottom: rem-calc(48);
    margin-top: 0;
    border-top: 1px solid $light-gray;
  }
  &--home {
    padding-bottom: 4rem;
  }
}

.section.background + .section.background {
  margin-top: 0;
  padding-top: $section-margin;
}

.section.home-headline {
  margin-top: rem-calc(24);
}

.section.home-headline + .section {
  margin-top: rem-calc(24);
}

.section.section--content-header + .section.background {
  margin-top: 0;
}

.section.background {
  padding-bottom: $section-margin;
}

.page-teaser:nth-child(2n) {
  background-color: $gray-blue;

  a.teaser-link__link {
    color: $black;

    .icon {
      background-image: url("assets/icons/arrow-black.svg");
    }
  }
}

.page-teaser:nth-child(4n) {
  background-color: $orange;
  h2 {
    color: $white;
  }

  a.teaser-link__link {
    color: $white;

    .icon {
      background-image: url("assets/icons/arrow-white.svg");
    }
  }
}

// white-space on blog special
body.articles--index .section:not(:first-of-type) {
  margin-top: 1rem;
}

;@import "sass-embedded-legacy-load-done:125";