.brands-title {
  margin-top: 95px;
}
.brands-teaser {
  &__wrapper {
    margin: $general-block-margin 0;
    display: grid;
    @include resprop(
      "grid-template-columns",
      (
        repeat(2, minmax(10px, 1fr)),
        repeat(3, minmax(10px, 1fr)),
        repeat(3, minmax(10px, 1fr))
      )
    );
    width: 100%;
    grid-gap: 16px;
    @include resprop("grid-auto-rows", (140px, 187px, 187px));
  }
  &__card {
    position: relative;
    color: white;
    background-color: #eff5f9;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    border-radius: 6px;

    & img {
      display: block;
      width: 100%;
      height: auto;
      max-height: 100%;
      object-fit: contain;
    }
  }
}

.full-column .brands-teaser__wrapper {
  @include resprop(
    "grid-template-columns",
    (
      repeat(2, minmax(10px, 1fr)),
      repeat(3, minmax(10px, 1fr)),
      repeat(4, minmax(10px, 1fr))
    )
  );
}

.side-content .brands-teaser__wrapper {
  grid-template-columns: 1fr;
}

;@import "sass-embedded-legacy-load-done:160";