.projects-link {
  display: inline-block;
}

.projects-link__wrapper {
  color: $orange;
  display: flex;

  & .icon {
    margin-left: 0.75rem;
  }
}

;@import "sass-embedded-legacy-load-done:167";