.event-registration-form {
  ul.errorlist {
    color: red;
    margin-bottom: 0;
  }
  @include breakpoint(medium up) {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    &__group {
      box-sizing: border-box;
      flex-basis: 50%;
      padding-right: rem-calc(16);

      &--textarea,
      &--radio {
        flex-basis: 100%;
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:171";