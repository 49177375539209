// reset
body.page--standard {
  border-color: $darker-gray;
}

// invert
body.page--inverted {
  background-color: $darker-gray;
  color: $white;
  border-color: $white;

  .nav-container:not(.toggled) {
    background-color: $white;
    color: $darker-gray;
  }

  .contacts {
    &__box {
      color: $darker-gray;
      background-color: $white;
      box-shadow: $box-shadow;
    }
  }

  .article-card__meta,
  .article-card__text-p,
  .article-card__text-h3 {
    color: $black;
  }

  .filter-item__label,
  .filter-pills__pill,
  .mobile-collapse {
    background-color: $white;
    color: $black;
  }

  .mobile-collapse,
  .filter-pills__pill:not(.open) {
    opacity: 0.8;
  }

  .filter-pills__mobile-collapse {
    border: 3px solid $white;
    @include breakpoint(large) {
      border: none;
    }
  }

  .expanded > .mobile-collapse {
    opacity: 1;
  }

  .hit-count {
    color: $light-gray;
  }

  .projects .card-label {
    color: $white;
  }

  .recommend-teaser .project-card .card-label.project-teaser__sub__h3 {
    color: $white;
  }

  .competencies {
    .card:not(:hover) {
      background-color: $white;
      box-shadow: $box-shadow;
    }
  }

  .filter__error {
    color: $black;
  }
}

;@import "sass-embedded-legacy-load-done:176";