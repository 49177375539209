.footer {
  border-top: rem-calc(6) solid $gray-blue;
  padding-top: rem-calc(64);
  padding-bottom: rem-calc(64);
  width: 100%;
  background-color: $darker-gray;
  color: $white;
  font-size: rem-calc(14);
}

main .section:last-child {
  margin-bottom: $section-margin;
}
main .section.background:last-child {
  margin-bottom: inherit;
}

.footer__meta {
  text-align: center;
  @include breakpoint(medium) {
    text-align: left;
  }
}

.footer__meta__link {
  line-height: 2;
  &:hover {
    text-decoration: underline;
  }
}

.footer__social {
  .icon {
    height: rem-calc(32);
    width: rem-calc(32);
  }
}

.footer__meta__copyright {
  font-weight: bold;
}

;@import "sass-embedded-legacy-load-done:122";