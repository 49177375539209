// @keyframes fadeIn {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }

// @keyframes delayedVisibility {
//   from {
//     visibility: collapse;
//   }
//   to {
//     visibility: visible;
//   }
// }

[data-appear] {
  opacity: 0;
}

[data-appear="fade-in-up"].is-inViewport {
  animation: 0.8s ease-out 0.5s 1 normal forwards running fadeUp;
}

[data-appear="fade-in-right"].is-inViewport {
  animation: 0.8s ease-out 0.5s 1 normal forwards running fadeInRight;
}

[data-appear="fade-in-left"].is-inViewport {
  animation: 0.8s ease-out 0.5s 1 normal forwards running fadeInLeft;
}

@keyframes fadeUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInRight {
  from {
    transform: translateX(50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    transform: translateX(-50px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

;@import "sass-embedded-legacy-load-done:121";