.page--project {
  h1 {
    font-size: rem-calc(25);
  }
  .text-neat {
    font-size: rem-calc(15);
    //color: inherit;
  }
  .text-lead {
    font-size: rem-calc(20);
    color: inherit;
  }
  .ce-rt h2,
  .ce-rt h3 {
    @extend %font-bold;
    font-size: 1em;
    margin-top: 2.5em;
  }
}

.projects {
  .card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .card-label {
      @extend %font-bold;
      font-size: rem-calc(20);
      padding: rem-calc(13) 0px;
    }

    img {
      border-radius: 3px;
      object-fit: cover;
      @include resprop("height", (236px, 236px, 316px));
    }
  }
}

.project-card__embedded-wrap {
  position: relative;
  overflow: hidden;
}

.project-card__embedded-icon {
  position: absolute;
  left: calc(50% - 44px);
  top: calc(50% - 44px);
  width: 88px;
  height: 88px;
}

;@import "sass-embedded-legacy-load-done:135";