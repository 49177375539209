$body-font-size-small: rem-calc(13);
$body-font-size-meta: rem-calc(15);
$body-font-large: rem-calc(20);
$body-font-size: rem-calc(16);
$body-line-height: 1.5;
$headings-line-height: 1.2;

$general-block-margin: rem-calc(27);
$general-margin: rem-calc(40);
$general-grid-margin: rem-calc(10);

%font-regular {
  font-family: "ObjektivMk2", sans-serif;
  font-weight: 400;
}

%font-medium {
  font-weight: 600;
}

%font-bold {
  font-weight: 700;
}

%font-black {
  font-weight: 800;
}

%meta-font {
  @extend %font-medium;
  font-size: $body-font-size-meta;
  line-height: rem-calc(21);
}

%lead-font {
  @extend %font-medium;
  font-size: $body-font-large;
}

%neat-font {
  @extend %font-medium;
  font-weight: normal;
  font-size: $body-font-size-meta;
  letter-spacing: rem-calc(0.33);
  text-transform: uppercase;
  line-height: rem-calc(25);
  color: $neat-gray;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  @extend %font-bold;
  margin-bottom: $general-block-margin;
  line-height: $headings-line-height;
}

h1 {
  margin-top: 0;
  @include resprop("font-size", (rem-calc(30), rem-calc(35), rem-calc(40)));
}

h2 {
  font-size: rem-calc(30);
}

h3 {
  font-size: rem-calc(25);
}

body {
  @extend %font-regular;
  font-size: $body-font-size;
  line-height: $body-line-height;
  color: $black;
  font-kerning: auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  box-sizing: border-box;
  margin: 0;
}

p,
form,
table {
  margin-top: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ul,
ol {
  margin-top: 0;
  margin-bottom: $general-block-margin;
  padding-left: 1.5rem;
}

ul {
  list-style-type: "・";
}

blockquote {
  margin: 0;
  padding: 0;
}

address {
  font-style: normal;
}

input,
textarea,
select,
option {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  color: inherit;
  border: none;
  font: unset;
  &:focus {
    outline: none;
    background-color: inherit;
  }
}

strong {
  @extend %font-bold;
}

// text mods
.text-neat {
  @extend %neat-font;
}

.text-meta {
  @extend %meta-font;
}

.text-lead {
  @extend %lead-font;
}

;@import "sass-embedded-legacy-load-done:117";