.page-teaser {
  padding-top: $section-margin;
  .card {
    @extend %font-medium;
    display: block;
    padding: 1rem 2.33rem 1rem 1.33rem;
    background-image: url("assets/icons/arrow-blue.svg");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-color: $white;
    min-height: rem-calc(108);
    font-size: rem-calc(15);

    .card-meta {
      @extend %font-bold;
      color: $medium-gray;
      margin-bottom: rem-calc(4);
    }
  }
}

;@import "sass-embedded-legacy-load-done:156";