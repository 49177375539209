.locations-teaser {
  &__wrapper {
    margin: $general-block-margin 0;
    display: grid;
    @include resprop(
      "grid-template-columns",
      (
        repeat(2, minmax(10px, 1fr)),
        repeat(3, minmax(10px, 1fr)),
        repeat(3, minmax(10px, 1fr))
      )
    );
    width: 100%;
    grid-gap: 16px;
    @include resprop("grid-auto-rows", (140px, 187px, 187px));
  }
  &__card {
    position: relative;
    color: white;
    background-color: black;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 6px;
    &__inner {
      position: absolute;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      backdrop-filter: blur(2px);
      background-color: $dark-blue;
      opacity: 0.5;
    }
  }
  &__h3 {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    font-size: rem-calc(30);
    hyphens: auto;
    text-align: center;
  }
}

.full-column .locations-teaser__wrapper {
  @include resprop(
    "grid-template-columns",
    (
      repeat(2, minmax(10px, 1fr)),
      repeat(3, minmax(10px, 1fr)),
      repeat(4, minmax(10px, 1fr))
    )
  );
}

.side-content .locations-teaser__wrapper {
  grid-template-columns: 1fr;
}

;@import "sass-embedded-legacy-load-done:159";