#cr_embed_signup {
  .editable_content {
    display: flex;
    flex-wrap: wrap;
  }

  .cr_form {
    &-input,
    &-select {
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 2.4375rem;
      margin: 0 0 1rem;
      border: 1px solid #cacaca;
      border-radius: 0;
      background-color: #fefefe;
      box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
      font-family: inherit;
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.5;
      color: #0a0a0a;
      transition:
        box-shadow 0.5s,
        border-color 0.25s ease-in-out;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    &-component {
      flex-basis: 50%;
      padding-right: rem-calc(20);
      display: flex;
      flex-direction: column;
      margin-bottom: rem-calc(20);

      @include breakpoint(small only) {
        flex-basis: 100%;
        padding-right: 0;
      }

      label {
        font-weight: 600;
      }
    }

    &-input {
      padding: 0.5rem;
    }

    &-label {
      font-weight: bold;
    }

    &-component--submit button {
      text-align: center;
      width: 100%;
      border-radius: 0;
      background-color: $gray-blue;
      color: $white;
      padding: 1rem 2rem;
      cursor: pointer;
    }

    &-component.firma label {
      font-weight: normal;
    }
  }

  // the following stuff is just taken over from the cleverreach css
  // code.
  .cr_ipe_item.inactive {
    display: none;
  }
  .imprint {
    font-size: 0.8em;
  }
  .cr_captcha {
    padding-left: 130px;
  }
  .cr_error {
    font-size: 1.1em;
    padding: 10px;
  }
  .clever_form_error {
    border: 1px solid #f22 !important;
  }
  .clever_form_note {
    margin: 26px 0 0 3px;
    position: absolute;
    display: inline;
    padding: 2px 4px;
    font-weight: bold;
    background-color: #f2ecb5;
    color: #000;
    font-size: 12px !important;
  }
}

;@import "sass-embedded-legacy-load-done:169";