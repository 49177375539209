.disturber {
  --orange: #ec652d;
  --yellow: #ffb248;
  --blue: #005470;

  z-index: z-index("disturber");

  position: fixed;

  width: rem-calc(88);
  height: rem-calc(88);
  font-size: rem-calc(9);
  top: 8rem;
  right: 1rem;

  @include breakpoint(medium) {
    top: 8rem;
    right: 1rem;
  }
  @include breakpoint(large) {
    width: rem-calc(128);
    height: rem-calc(128);
    font-size: rem-calc(13);
    top: 11rem;
    right: 2rem;
  }
  @include breakpoint(xxlarge) {
    top: 12rem;
    right: clamp(10rem, 8vw, 100%);
  }

  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.3);
  }

  &__inner {
    border-radius: 50%;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--aspect-color);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    // box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.25);

    padding: 1rem;
  }

  &__name {
    transform: rotate(-10deg);

    color: $white;
    text-align: center;

    // text-shadow: 1px 1px 3px $black;
  }
}

.aspect-orange {
  --aspect-color: var(--orange);
}

.aspect-yellow {
  --aspect-color: var(--yellow);
}

.aspect-blue {
  --aspect-color: var(--blue);
}

.scrolled .disturber {
  transform: scale(0.45);
  right: -1rem;

  .disturber__inner {
    background-size: 0;
  }

  .disturber__name {
    opacity: 0;
  }

  &:hover {
    transform: scale(1);
    right: 0.5rem;

    .disturber__inner {
      background-size: cover;
    }

    .disturber__name {
      opacity: 1;
    }
  }
}

;@import "sass-embedded-legacy-load-done:173";