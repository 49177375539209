form label.required {
  &::after {
    content: "*";
  }
}

form ul {
  padding: 0;
  list-style: none;
}

fieldset {
  border: none;
}

;@import "sass-embedded-legacy-load-done:141";