/**
  * @license
  * MyFonts Webfont Build ID 3556837, 2018-04-12T05:38:55-0400
  *
  * The fonts listed in this notice are subject to the End User License
  * Agreement(s) entered into by the website owner. All other parties are
  * explicitly restricted from using the Licensed Webfonts(s).
  *
  * You may obtain a valid license at the URLs below.
  *
  * Webfont: ObjektivMk2-Bold by Dalton Maag
  * URL: https://www.myfonts.com/fonts/daltonmaag/objektiv/2bold/
  * Copyright: Copyright 2015 Dalton Maag Ltd. Modification of this file requires prior written permission from Dalton Maag Ltd.
  * Licensed pageviews: Unlimited
  *
  * Webfont: ObjektivMk2-Regular by Dalton Maag
  * URL: https://www.myfonts.com/fonts/daltonmaag/objektiv/2regular/
  * Copyright: Copyright 2015 Dalton Maag Ltd. Modification of this file requires prior written permission from Dalton Maag Ltd.
  * Licensed pageviews: Unlimited
  *
  * Webfont: ObjektivMk2-Black by Dalton Maag
  * URL: https://www.myfonts.com/fonts/daltonmaag/objektiv/2black/
  * Copyright: Copyright 2015 Dalton Maag Ltd. Modification of this file requires prior written permission from Dalton Maag Ltd.
  * Licensed pageviews: Unlimited
  *
  * Webfont: ObjektivMk2-Medium by Dalton Maag
  * URL: https://www.myfonts.com/fonts/daltonmaag/objektiv/2medium/
  * Copyright: Copyright 2015 Dalton Maag Ltd. Modification of this file requires prior written permission from Dalton Maag Ltd.
  * Licensed pageviews: Unlimited
  *
  * License: https://www.myfonts.com/viewlicense?type=web&buildid=3556837
  *
  * © 2018 MyFonts Inc
  */

@font-face {
  font-family: "ObjektivMk2";
  font-weight: 400;
  src: url("assets/webfonts/ObjektivMk2-Regular.woff2");
  src:
    url("assets/webfonts/ObjektivMk2-Regular.woff2") format("woff2"),
    url("assets/webfonts/ObjektivMk2-Regular.woff") format("woff");
}
@font-face {
  font-family: "ObjektivMk2";
  font-weight: 700;
  src: url("assets/webfonts/ObjektivMk2-Bold.woff2");
  src:
    url("assets/webfonts/ObjektivMk2-Bold.woff2") format("woff2"),
    url("assets/webfonts/ObjektivMk2-Bold.woff") format("woff");
}
@font-face {
  font-family: "ObjektivMk2";
  font-weight: 800;
  src: url("assets/webfonts/ObjektivMk2-Black.woff2");
  src:
    url("assets/webfonts/ObjektivMk2-Black.woff2") format("woff2"),
    url("assets/webfonts/ObjektivMk2-Black.woff") format("woff");
}
@font-face {
  font-family: "ObjektivMk2";
  font-weight: 600;
  src: url("assets/webfonts/ObjektivMk2-Medium.woff2");
  src:
    url("assets/webfonts/ObjektivMk2-Medium.woff2") format("woff2"),
    url("assets/webfonts/ObjektivMk2-Medium.woff") format("woff");
}

;@import "sass-embedded-legacy-load-done:1";