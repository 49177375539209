$ot: "ot_areale-und-offentlicher-raum", "ot_bahnhofe", "ot_buro-verwaltung",
  "ot_einkaufszentren", "ot_einsatzzentralen-polizei-verkehr",
  "ot_energieversorgung", "ot_flughafen", "ot_grosskuchen-restaurant",
  "ot_heime-altersheime-seniorenresidenz", "ot_hotels", "ot_immobilien",
  "ot_industriegebaude", "ot_kirchen", "ot_laborgebaude", "ot_wohnbauten",
  "ot_spitaler-pflege-ops", "ot_parkhauser", "ot_rechenzentren",
  "ot_tunnelsnationalstrasse", "ot_museen-kongress-theater-konzertgebaude-kino",
  "ot_sportstatten-stadien-schwimmbader-turnhallen",
  "ot_sicherheitsanlagen-schutzbauten-gefangnisse", "ot_lager-logistikgebaude",
  "ot_kraftwerke", "ot_handelsraume-finanz", "ot_energiezentralen",
  "ot_schulen-unterrichtsgebaude-bibliotheken";

.filter-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @each $icon in $ot {
    &.#{$icon} {
      background-image: url("assets/icons/cr/#{$icon}.svg");
    }
  }
  &.phase-icon {
    @extend %font-black;
    font-size: 1.25em;
    transform: scale(0.8);
    border-radius: 50%;
    border: 3px solid;
    &.p_development {
      border-color: $orange;
      &::after {
        content: "1";
      }
    }
    &.p_planning {
      border-color: $yellow;
      &::after {
        content: "2";
      }
    }
    &.p_realization {
      border-color: $dark-blue;
      &::after {
        content: "3";
      }
    }
    &.p_maintenance {
      border-color: $green;
      &::after {
        content: "4";
      }
    }
  }
}

;@import "sass-embedded-legacy-load-done:138";