.cell.left-grid-offset {
  @include breakpoint(medium) {
    padding-left: 12%;
  }
}

.full-column .cell.left-grid-offset {
  @include breakpoint(medium) {
    padding-left: 10%;
  }
}

.cell.right-grid-offset {
  @include breakpoint(medium) {
    padding-right: 12%;
  }
}

.full-column .cell.right-grid-offset {
  @include breakpoint(medium) {
    padding-right: 10%;
  }
}

.side-content {
  .cell.left-grid-offset {
    padding-left: 0;
  }
  .cell.right-grid-offset {
    padding-right: 0;
  }
}

;@import "sass-embedded-legacy-load-done:128";