.search-result {
  &__container {
    padding: 1rem;
    margin: 2rem 0rem;
    border-radius: 3px;
    background-color: $white;
  }
  &__text-block {
    @include breakpoint(medium) {
      padding-left: 1rem;
    }
    h3 {
      margin: 1rem 0;
      line-height: 1.66;
      span {
        border-bottom: 2px solid $gray-blue;
        &:hover {
          color: $gray-blue;
        }
      }
    }
    margin-bottom: rem-calc(25);
  }
  &__img {
    display: flex;
    align-items: center;
    justify-content: space-around;
    img {
      max-height: calc(240px - 2rem);
      border-radius: 3px;
    }
  }
}

.section--search-results .pagination {
  display: flex;
  justify-content: center;
  &__number {
    padding: 0 0.5rem;
    @include breakpoint(medium) {
      padding: 0 1rem;
    }
  }
  .pagination__link {
    text-decoration: underline;
  }
  .pagination__current-page-number {
    @extend %font-bold;
    text-decoration: none;
  }
}

.search-form {
  display: flex;
  flex-direction: column;
  @include breakpoint(medium) {
    flex-direction: row;
  }
  flex-wrap: wrap;
  background: $white;
  border-radius: 3px;
  //   padding: 1rem;
  fieldset {
    flex: 1 0 auto;
    margin: 0;
    padding: 1rem 1rem;
  }
  fieldset > label {
    @extend %font-bold;
    margin-bottom: 0.3rem;
    display: block;
  }
  fieldset > input {
    border: 1px solid $light-gray;
    width: 100%;
    border-radius: 10px;
    padding: 0.66rem 1.33rem;
  }
  fieldset.submit {
    text-align: right;
  }
  ul {
    padding: 0.66rem 0rem;
    margin: 0;
  }
  li {
    display: inline-block;
    label {
      cursor: pointer;
      margin-right: 10px;
    }
  }
}

;@import "sass-embedded-legacy-load-done:145";