.site-search-pill {
  &__wrapper {
    position: absolute;
    left: 0;
    width: 100%;
  }
  &__pill {
    margin-top: 0.5 * $general-block-margin;
    float: right;
    box-shadow: $box-shadow;
    border-radius: 1.2em;
    padding: 0.3em;
    background-color: white;
  }
  &__input {
    &:placeholder-shown {
      @include transition(width);
      width: 120px;
    }
    &:focus {
      width: 214px;
    }
    &::placeholder {
      opacity: 1;
      color: $medium-gray;
    }
  }
}

// normalize foundation forms
label.site-search-pill__label {
  line-height: inherit;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: $body-font-size-meta;
}

;@import "sass-embedded-legacy-load-done:144";