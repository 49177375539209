.infobox {
  color: white;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.infobox--berry {
  background-color: $wine;
}

.infobox--blue {
  background-color: $gray-blue;
}

.infobox--gray {
  background-color: $dark-gray;
}

.infobox__inner {
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
  flex-wrap: nowrap;
  gap: 40px;
}

.infobox__image {
  flex: 0 0 auto;
  display: block;
  width: 84px;
  height: 84px;
  border-radius: 50%;
  overflow: hidden;
}

.infobox__content {
  .infobox__title {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .infobox__text {
    & p:last-child,
    & ul:last-child {
      margin-bottom: 0;
    }
  }
}

.infobox__text--columns {
  columns: 2 auto;
  column-gap: 40px;

  li {
    page-break-inside: avoid; /* For Firefox. */
    -webkit-column-break-inside: avoid; /* For Chrome & friends. */
    break-inside: avoid;
  }
}

@media screen and (max-width: $mobile) {
  .infobox__inner {
    flex-wrap: wrap;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 27px;
    padding-bottom: 27px;
  }

  .infobox__text--columns {
    columns: auto;
  }
}

;@import "sass-embedded-legacy-load-done:175";