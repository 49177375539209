$phase-circle-duration: 1500ms;
$phase-circle-easeing-function: cubic-bezier(0.22, 0.61, 0.36, 1);

$phase-circle-colors: (
  0: $orange,
  1: $yellow,
  2: $dark-blue,
  3: $green,
);

.phase-circle {
  position: relative;
  max-width: 8.5rem;
  left: 2rem;
}

.phase-circle__number {
  @extend %font-black;
  font-size: rem-calc(34);
  line-height: 1;
  text-align: center;

  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  transform: translateY(-50%) scale(0);

  width: 100%;
  margin-top: 0.05em;

  .animate & {
    transition: transform $phase-circle-easeing-function $phase-circle-duration /
      2;
  }

  @for $i from 0 through 3 {
    [data-phase-circle="#{$i + 1}"] & {
      transition-delay: $phase-circle-duration / 4 + (0.1s * $i);
    }

    [data-phase-circle="#{$i + 1}"].animate & {
      transform: translateY(-50%) scale(1);
    }
  }
}

.phase-circle__svg {
  display: block;
  transform: rotate(-90deg);

  .animate & {
    transition: $phase-circle-duration * 2 $phase-circle-easeing-function
      transform;
  }

  @for $i from 0 through 3 {
    [data-phase-circle="#{$i + 1}"].animate & {
      transform: rotate(90deg * $i);
    }
  }
}

.phase-circle__progress,
.phase-circle__base {
  stroke-width: 3;
  fill: none;

  @for $i from 0 through 3 {
    [data-phase-circle="#{$i + 1}"] & {
      stroke: map-get($phase-circle-colors, $i);
    }
  }
}

.phase-circle__base {
  stroke-opacity: 0.3;
}

.phase-circle__progress {
  stroke-dasharray: 0;
  stroke-dashoffset: 0;

  .animate & {
    transition: stroke-dasharray $phase-circle-duration
      $phase-circle-easeing-function;
  }
}

;@import "sass-embedded-legacy-load-done:151";