$card-meta-font-size: rem-calc(10);
$card-font-size: rem-calc(13);
$card-padding: rem-calc(12);
$card-heading-font-size: rem-calc(15);

.article-card {
  background-color: $white;
  margin-bottom: $general-block-margin;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  &__img {
    overflow: hidden;
    border-radius: 3px;
  }
  &__img img {
    @include transition(transform);
  }
  &__meta {
    padding: 0 $card-padding;
    span {
      @extend %neat-font;
      font-size: $card-meta-font-size;
      color: $medium-gray;
    }
  }
}

.article-card:hover img {
  transform: scale(1.1);
}

.article-card {
  .article-card__text-wrap {
    padding: $card-padding $card-padding 0 $card-padding;
    margin-bottom: 2 * $card-padding;
  }
  .article-card__text-h3 {
    h3 {
      @extend %meta-font;
      @extend %font-bold;
      margin-top: 0;
    }
  }
  .article-card__text-p {
    @include line-clamp(3, 1.66);
    p {
      font-size: $card-font-size;
      margin-bottom: 0;
    }
  }
  .article-card__embedded-wrap {
    position: relative;
  }
  .article-card__embedded-icon {
    position: absolute;
    left: calc(50% - 44px);
    top: calc(50% - 44px);
    width: 88px;
    height: 88px;
  }
}

// reset section margins
.article-meta,
.article-detail {
  margin-top: $general-block-margin;
}

// reset for offset bodytext, specialcase
.article-detail .left-grid-offset.ce-rt > h2 {
  margin-top: 2 * $general-block-margin;
  line-height: rem-calc(42);
  font-size: rem-calc(25);
}

.article-teaser {
  background-color: $lightest-gray;
}

.article__info-text {
  margin-bottom: 0.5 * $general-block-margin;
}

// special pagination
.articles__load-more-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  // margin-top: -2rem;
  .filter__loading {
    position: absolute;
    width: 100%;
    overflow: inherit;
    [data-loading~="filter"] & {
      height: 4rem;
    }
    .filter__loading-circle {
      background-color: $orange;
    }
  }
}
.articles__load-last,
.articles__load-next {
  display: none;
  position: absolute;
  top: calc(50% - rem-calc(24));
}
.articles__load-last {
  left: 0;
}
.articles__load-next {
  right: 0;
}
.articles__load-icon {
  width: rem-calc(48);
  height: rem-calc(48);
}
// list specifics
.top-article {
  .article-card {
    background-color: inherit;
    &__wrap {
      @include breakpoint(large) {
        display: flex;
      }
    }
    &__info {
      @include breakpoint(large) {
        margin-left: $general-block-margin;
        width: 33%;
      }
    }
    &__meta {
      span {
        @extend %font-medium;
        font-size: rem-calc(13);
        color: $orange;
      }
    }
    &__text-wrap {
      .article-card__text-h3 {
        h3 {
          font-size: rem-calc(20);
          line-height: 1.5;
        }
      }
      .article-card__text-p {
        @include line-clamp(7, 1.66);
        p {
          @extend %font-medium;
          font-size: rem-calc(15);
          margin-bottom: 0;
        }
      }
      margin-bottom: 0;
    }
  }
  .top-article-icon {
    float: right;
    width: rem-calc(48);
    height: rem-calc(48);
    margin-top: rem-calc(18);
  }
}

;@import "sass-embedded-legacy-load-done:129";